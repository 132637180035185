/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import useEventListener from '../../hooks/useEventListener';

const NEXT_KEY = 39;
const PREVIOUS_KEY = 37;

const ActiveEntryViewer = ({ entry, handleGalleryClose }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNextItemSelection = () => {
    if (activeIndex === entry.gallery_control.galleryImages.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePreviousItemSelection = () => {
    if (activeIndex - 1 < 0) {
      setActiveIndex(entry.gallery_control.galleryImages.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const wrapperRef = React.createRef();
  useClickOutsideHandler(wrapperRef, handleGalleryClose);

  const handleKeyDownPress = (e) => {
    if (e.keyCode === NEXT_KEY) {
      handleNextItemSelection();
    } else if (e.keyCode === PREVIOUS_KEY) {
      handlePreviousItemSelection();
    }
  };

  useEventListener('keydown', handleKeyDownPress);

  return (
    <div className="entry-viewer">
      <div ref={wrapperRef} className="content">
        <img alt={entry.id} key={entry.id} src={entry.gallery_control.galleryImages[activeIndex].mediaItemUrl} />
        <h1>{entry.title}</h1>
        <p>{entry.gallery_control.galleryPreview}</p>
      </div>
      <span onClick={handleGalleryClose} className="material-icons close-icon">
        close
      </span>
      <div id="nav" className="navigation">
        <span onClick={handlePreviousItemSelection} className="material-icons">
          navigate_before
        </span>
        <span onClick={handleNextItemSelection} className="material-icons">
          navigate_next
        </span>
      </div>
    </div>
  );
};

export default ActiveEntryViewer;
