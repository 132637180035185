/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const GalleryEntry = ({ entry, setActiveEntry }) => {
  const content =
    entry.gallery_control.galleryPreview.length > 60
      ? `${entry.gallery_control.galleryPreview.slice(0, 59)}...`
      : entry.gallery_control.galleryPreview;

  return (
    <div onClick={setActiveEntry} className="gallery-entry">
      <img alt={entry.id} key={entry.id} src={entry.gallery_control.galleryImages[0].mediaItemUrl} />
      <subline>
        <span>{entry.title}</span>
        <br />
        {content}
        <br />
        <br />
      </subline>
      <span className="date">{new Date(entry.date).toLocaleDateString()}</span>
    </div>
  );
};

export default GalleryEntry;
