/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const DatesMenu = ({ activeSelection, dateMenuEntries, handleGalleryFilter }) => {
  return (
    <div className="dates-menu">
      <ul>
        <li className={activeSelection === 'All' ? 'active' : ''} onClick={() => handleGalleryFilter('All')}>
          Wszystkie
        </li>
        {dateMenuEntries.map((entry, index) => {
          const keyIndex = index;

          const classes = [];
          if (activeSelection === entry) {
            classes.push('active');
          }
          if (!entry.month) {
            classes.push('year-entry');
          }
          const classNames = classes.join(' ');

          return (
            <li
              onClick={() => handleGalleryFilter(entry)}
              className={classNames}
              key={keyIndex.toString() + entry.year.toString()}
            >
              {entry.month && <span />}
              {entry.month || entry.year}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DatesMenu;
